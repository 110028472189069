import type { Organization } from '@/types/organization';

import { organizationSchema } from '@/types/organization';
import { zod } from '@/utils';

export const useOrganizationStore = defineStore('organization', () => {
  const organization = ref<Organization | null>(null);
  const xeroContactId = ref<string | null>(null);
  const isDemo = ref<boolean | null>(null);

  const setOrganization = (org: Organization) => {
    const { data, error } = zod.verifyData(org, organizationSchema);

    if (error) {
      organization.value = null;
      return;
    }

    organization.value = data;
  };

  const setXeroContactId = (id: string) => {
    xeroContactId.value = id;
  };

  const organizationId = computed(() => organization.value?.organization_id ?? 0);
  const organizationName = computed(() => organization.value?.organization_name ?? '');
  const customerId = computed(() => organization.value?.customer_id ?? 0);
  const demoName = computed(() => organization.value?.demo_name ?? '');
  const isOverdue = computed(() => !!organization.value?.overdue);

  return {
    /**
     * Organization data
     */
    organization,
    /**
     * Zendesk organization name
     */
    organizationName,
    /**
     * Set organization data
     */
    setOrganization,
    /**
     * Zendesk organization ID, e.g. 90000000000
     */
    organizationId,
    /**
     * CRM customer ID, e.g. 10000
     */
    customerId,
    /**
     * Xero contact ID
     */
    xeroContactId,
    /**
     * Set Xero contact ID
     */
    setXeroContactId,
    /**
     * Is this organization a demo organization
     */
    isDemo,
    /**
     * Demo organization name
     */
    demoName,
    /**
     * Whether this organization has overdue invoices
     */
    isOverdue,
  };
});
