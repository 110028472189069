import type { XeroContactPayload } from '@/types/contact';

import client from '@/services/api/RemovifyServiceV2/config';

const prefix = '/billing';

export const verifyOrderSubmission = async (organization_id: number) => {
  return client.post(`${prefix}/pre-order-verify/${organization_id}`);
};

export const createBillingContactPerson = async (organization_id: number, data: XeroContactPayload) => {
  const { contact_id, ...postData } = data;
  return client.post(`${prefix}/organization/${organization_id}/contact/${contact_id}`, postData);
};

export const updateBillingContactPerson = async (organization_id: number, email: string, data: XeroContactPayload) => {
  const { contact_id, ...postData } = data;
  return client.put(`${prefix}/organization/${organization_id}/contact/${contact_id}/person/${email}`, postData);
};

export const deleteBillingContactPerson = async (organization_id: number, contact_id: string, email: string) => {
  return client.delete(`${prefix}/organization/${organization_id}/contact/${contact_id}/person/${email}`);
};

export const makePrimaryBillingContact = async (organization_id: number, contact_id: string, email: string) => {
  return client.patch(`${prefix}/organization/${organization_id}/contact/${contact_id}/person/${email}`, {
    is_primary_billing_contact: true,
  });
};

export default {
  verifyOrderSubmission,
  createBillingContactPerson,
  updateBillingContactPerson,
  deleteBillingContactPerson,
  makePrimaryBillingContact,
};
